import { Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from 'app/core/services';
import { environment } from 'environments/environment';
import { ConsoleApi } from 'lib/console-api/console-api.service';
import { map, pairwise } from 'rxjs';
import { PriceGroupStore } from './price-group/store/price-group.store';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [AuthService, ConsoleApi],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private auth = inject(AuthService);
  private router = inject(Router);
  private consoleApi = inject(ConsoleApi);
  private priceGroupStore = inject(PriceGroupStore);

  title = 'Raiden Console';

  readonly isLoggedInPair = toSignal(
    this.auth.isLoggedIn.pipe(
      pairwise(),
      map(([prev, curr]) => {
        return {
          prev,
          curr
        }
      })
    )
  );

  constructor() {
    if (!environment.production) {
      this.consoleApi.ping().then(resp => {
        console.log(`API version: ${resp?.getVersion()}`);
      });
    }

    effect(() => {
      const isLoggedInPair = this.isLoggedInPair();
      if (!isLoggedInPair?.prev && isLoggedInPair?.curr) {
        this.router.navigateByUrl('/home');
      } else if (isLoggedInPair?.prev && !isLoggedInPair?.curr) {
        this.router.navigateByUrl('/login');
      }
    });

    this.initializeWatchers();
  }

  initializeWatchers() {
    this.priceGroupStore.watchPriceGroups();
  }
}
