import { inject } from '@angular/core';
import { CanActivateFn, Router, Routes } from '@angular/router';
import { AuthService } from 'app/core/services';
import { map } from 'rxjs';

export const homeGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.authState().pipe(
    map(user => {
      if (user) {
        return true;
      }
      return router.createUrlTree(['/login']);
    })
  );
}

const loginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.authState().pipe(
    map(user => {
      if (user) {
        return router.createUrlTree(['/home']);
      }
      return true;
    })
  );
}

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.isAuthorized.pipe(
    map((isAuthorized) => {
      if (isAuthorized) {
        return true;
      }
      return router.createUrlTree(['/home/unauthorized']);
    })
  );
}

export const unauthGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.isAuthorized.pipe(
    map((isAuthorized) => {
      if (!isAuthorized) {
        return true;
      }
      return router.createUrlTree(['/home']);
    })
  );
}

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
    providers: [AuthService],
    canActivate: [loginGuard]
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
    providers: [AuthService],
    canActivate: [homeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'businesses'
      },
      {
        path: 'businesses',
        loadComponent: () => import('./businesses/businesses.component').then(m => m.BusinessesComponent),
        canActivate: [authGuard]
      },
      {
        path: 'businesses/:id',
        loadComponent: () => import('./businesses/edit/businesses-edit.component').then(m => m.BusinessEditComponent),
        canActivate: [authGuard]
      },
      {
        path: 'unauthorized',
        loadComponent: () => import('./unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
        canActivate: [unauthGuard]
      }
    ]
  },
  { path: '**', redirectTo: 'home' }
];
